.contact-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 18em; 
    /* background-image: url(../Assets/images/skyline.jpg); */
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 
}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.contact-body{
    margin: 4em auto; 
    height: max-content; 
    width: 86%; 
}

.contact-image{
    margin: 0 auto; 
    background-image: url(./images/career.jpg); 
    background-size: cover;
    background-position: 0px 0px; 
    height: 14em;  
    width: 100%; 
    border: 0; 
    border-radius: 0px; 
}

.contact-form{
    height: 40em;
    width: 100%; 
    background: rgb(249,249,249); 
}

.form{
    margin: 0 auto; 
    width: 90%; 
}

.form-description{
    height: 10em; 
    overflow-y: scroll; 
    resize: none;
}

.button-container{
    margin-left: auto;
}

.btn-primary {
    background-color: rgb(35,119,186);
}

.btn-primary:hover{
    background-color: white; 
    color:  rgb(35,119,186);
    border-style: solid; 
    border-color: rgb(35,119,186);
}