.buy-options {
    background-color: rgb(249, 249, 249);
    padding-bottom: 01%;
}

.buy-form-label {
    background: #1c75bc;
    color: #fff;
    padding: 10px 22px;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: -15px;
    margin-right: -15px;
}

.buy-page-title {
    background-image: url(./images/city-backdrop.jpg);
}

.buy-sec-title {
    position: relative;
    margin-bottom: 40px;
}

.buy-sec-title h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 35px;
    border-bottom: 1px solid #1c75bc;
}

.buy-page-title {
    position: relative;
    padding: 200px 0px 40px;
    color: #ffffff;
    background-color: #ffffff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.buy-page-title:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(4, 4, 4, 0.50);
}

.buy-page-title .auto-container {
    position: relative;
    z-index: 1;
}

.buy-page-title h1 {
    position: relative;
    font-size: 32px;
    line-height: 44px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.buy-page-title .bread-crumb {
    position: relative;
    padding-top: 15px;
    text-align: right;
}

.buy-page-title .bread-crumb li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    margin-left: 20px;
    color: #ffffff;
    font-size: 13px;
    text-transform: capitalize;
}

.buy-page-title .bread-crumb li:before {
    content: '/';
    position: absolute;
    right: -20px;
    top: 0px;
    width: 20px;
    text-align: center;
    line-height: 30px;
}

.buy-page-title .bread-crumb li:first-child {
    margin-left: 0px;
}

.buy-page-title .bread-crumb li:last-child:before {
    display: none;
}

.buy-page-title .bread-crumb li a {
    color: #ffffff;
}

.buy-page-title .bread-crumb li a:hover {
    color: #1c75bc;
}

.buy-options h4 {}

.buy-form-group, .buy-form-dropdown {
    padding: 10px 22px;
}