.valuation-page{
    height: max-content; 
}

.jumbo{
    position: relative; 
    height: 18em; 
    background-image: url(../Assets/images/skyline.jpg);
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.searchbox{
    color: white; 
    background-color: rgb(43,43,43);
}

.searchbox-content{
    margin: 0 auto; 
    height: 20%; 
    width: 80%; 
}

.searchdropdown{
    margin: 4em auto; 
    width: 100%; 
}

.search-buttons{
    margin-top: -3em;
    text-align: center;
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
    border-color: transparent; 
}

.button:hover{
    background-color: white; 
    color: rgb(52,127,187); 
    border-style: 2px solid; 
    border-color: rgb(52,127,187);
}

.valuation-body{
    text-align: center; 
    margin: 2em auto; 
    height: max-content; 
    width: 90%; 
}

.value-search-card{
    border: 0; 
    margin: 0 auto;
    background: rgb(249,249,249); 
    height: 20em; 
    width: 80%; 
}

.valuation-form{
    margin-top: 3em; 
}

.value-control{
    margin: 0 auto;
    height: 3em; 
    width: 50%; 
}

.getdetails-button{
    background: rgb(52,127,187); 
}

.getdetails-button:hover{
    background-color: white; 
    color: rgb(52,127,187); 
}