/* .carousel-fade .carousel-item, .carousel-item img  {
    max-height: 90vh;
  } */

/* .main-pic {
  min-height: 400px;
  min-width: 650px;
  max-width: 670px;
}

.main-pic img {
  text-align: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.main-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  height: 65vh;
}

.main-pic img {
  flex-shrink: 0;
  min-width: 80%;
  min-height: 80%;
}

.maximize {
  position: absolute;
  z-index: 99999;
  top: 5px;
  right: 5px;
  /* padding: 7px 15px; */
  border-radius: 5px;
  background-color: rgba(30, 35, 39, 0.062);
  text-align: right;
  display: inline-block;
  width: 31px;
  height: 31px;
  text-align: center;
  cursor: pointer;
}

.icon {
  margin-top: 0px;
  width: 30px;
  height: 30px;
  color: rgb(14, 36, 53);
}

.my-modal {
  width: 61vw; /* Occupy the 90% of the screen width */
  max-width: 61vw;
  height: auto;
  max-height: 90vh;
  color: rgba(225, 231, 236, 0.651);
  text-align: center;
}

.test {
  height: auto;
  width: 100%;
}

.modal-content {
  border: 0;
  background-color: #f1f5f800;
}

.close {
  float: right;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  color: rgb(179, 232, 248);
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  background-color: rgba(64, 235, 149, 0.322);
}

.close:hover {
  color: rgb(206, 232, 240);
}

 
.selected-row {
  flex-wrap: inherit !important;
}