.submit-property-page {
  height: 100%;
  margin-bottom: 40px;
}

.jumbo {
  position: relative;
  height: 18em;
  background-image: url(../../Assets/images/skyline.jpg);
  opacity: 1;
  background-size: cover;
  background-position: 0px -50px;
  background-repeat: no-repeat;
  border-radius: 0px;
  margin-bottom: 0;
  width: 100%;
}

.jumbo::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.64);
  margin-bottom: 0;
}

.jumbo-content-left {
  position: relative;
  margin: 10em 2em;
  width: 80%;
  color: white;
}

.searchbox {
  color: white;
  background-color: rgb(43, 43, 43);
}

.searchbox-content {
  margin: 0 auto;
  height: 20%;
  width: 80%;
}

.searchdropdown {
  margin: 4em auto;
  width: 100%;
}

.search-buttons {
  margin-top: -3em;
  text-align: center;
}

.button {
  margin-left: 8px;
  background-color: rgb(52, 127, 187);
  border-color: transparent;
}

.button:hover {
  background-color: white;
  color: rgb(52, 127, 187);
  border-style: 2px solid;
  border-color: rgb(52, 127, 187);
}

.submit-property-body {
  text-align: left;
  margin: 2em auto;
  height: 100%;
  margin-bottom: 40px;
  width: 90%;
}

.submit-property-card {
  border: 0;
  margin: 0 auto;
  background: rgb(249, 249, 249);
  height: 100%;

  width: 80%;
  padding: 20px;
  padding-bottom: 200px;
}

.submit-property-form {
  margin-top: 3em;
}

.value-control {
  margin: 0 auto;
  height: 3em;
  width: 50%;
}

.bulk-upload-button {
  background: rgb(52, 127, 187);
  border: 0;
  width: 137px;
  margin-bottom: 10px;
}

.bulk-upload-button:hover {
  background-color: white;
  color: rgb(52, 127, 187);
  border: solid 1px rgb(52, 127, 187);
}

.small-header-row-submit {
  width: 30px;
  height: 1px;
  background-color: #1c75bc;
  text-align: left;
  margin-top: 10px;
}

.small-header-row-submit-plan {
  width: 30px;
  height: 1px;
  background-color: #1c75bc;
  text-align: center;
  margin-top: -5px;
}

.property.row {
  margin-right: 0px;
  margin-left: 0px;
}

.submit-property-header {
  padding: 20px;
}

.upload-button {
  text-align: right;
}

.link-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #1c75bc;
}

.submit-content {
  margin: 0 auto;
  width: 90%;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

.property.form-input {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  line-height: 24px;
  padding: 9px 15px;
  height: 44px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button,
input,
optgroup,
select,
.property.textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  line-height: 24px;
}

.submit-button-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.submit-button {
  background: rgb(52, 127, 187);
  border: 0;
  width: 50%;
}

.submit-button:hover {
  background-color: white;
  color: rgb(52, 127, 187);
  border: solid 1px rgb(52, 127, 187);
}

.property-submit-card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4.5px 2px -4px;
  background-color: #f8f9fb;
  color: rgb(85, 84, 84);
  border-radius: 5px;
  padding: 10px;
  margin: 2px;
}

.submit-button-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.btn-primary {
  position: relative;
  padding: 9px 25px;
  line-height: 24px;
  text-transform: capitalize;
  background: #1c75bc;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #1c75bc !important;
}

.btn-primary:hover {
  background-color: white;
  color: #1c75bc;
  border: solid 1px #1c75bc;
}

.upload-images {
  cursor: pointer;
}

.custom-input {
  appearance: initial;
  background-image: url(../../Assets/images/upload-pic.png);
  background-repeat: no-repeat;
  padding: 100px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-size: 70px 70px;
}

.custom-input:focus {
  border: 0;
  outline: none;
}

input:focus {
  border: 0;
  outline: none;
}

.pic {
  text-align: left;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  vertical-align: middle;
  border-radius: 5px;
  margin: 0;
}

.pic img {
  text-align: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove {
  position: absolute;
  z-index: 99;
  top: -10px;
  right: 50px;
  /* padding: 7px 15px; */
  background-color: rgb(255, 255, 255);
  text-align: right;
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}

.icon {
  margin-top: 0px;
  width: 30px;
  height: 30px;
}
