.login-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 18em; 
    /* background-image: url(../Assets/images/skyline.jpg); */
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 
}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.login-page{
    height:max-content; 
}

.searchbox{
    color: white; 
    background-color: rgb(43,43,43);
}

.searchbox-content{
    margin: 0 auto; 
    height: 20%; 
    width: 80%; 
}

.searchdropdown{
    margin: 4em auto; 
    width: 100%; 
}

.search-buttons{
    margin-top: -3em;
    text-align: center;
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.button:hover{
    background-color: white; 
    color: rgb(52,127,187); 
    border-style: 2px solid; 
    border-color: rgb(52,127,187);
}

.account{
    margin: 4em auto; 
    width: 86%; 
    text-align: center;
}

.sign{
    margin: 4em auto; 
    height: max-content; 
    width: 100%;
}

.signin-card{
    height:max-content; 
    width:100%; 
    background-color: rgb(249,249,249);
    margin-right: auto;
}

@media only screen and (min-width: 1080px){
    .signin-card{
        height:24em;
    }
}

.alternate-login{
    margin: 0 auto; 
}

.oauth-login:hover{
    /* fill: black;  */
    transform: scale(1.06)
}

.email, .password, .remember, .forgot, .name{
    text-align: left;
}

.form-control{
    width: 100%; 
}

.email-control, .password-control{
    width: 100%; 
}

.sign-button{
    height:3em; 
    width: 80%; 
    background-color: rgb(52,127,187);
    color: white; 
}


.signup-content{
    margin: 0 auto; 
    width: 90%; 
}

.make-name{
    text-align: left;
}

.name-control, .make-email-control, .make-web-control, .make-password-control{
    width: 100%; 
}

.make-email, .make-web, .make-password, .attach{
    text-align: left; 
}


.sign-up-button{
    margin-top: 30px; 
    height:3em; 
    width: 80%; 
    border-color:rgb(52,127,187);
    background-color: rgb(211,211,211);
    color: white; 
}

.sign-up-button:hover{
    color: rgb(52,127,187);
    background-color: rgb(211,211,211);
}
