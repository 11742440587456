.careers-page{
    height: max-content;
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 40%; 
    /* background-image: url(../Assets/images/skyline.jpg); */
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.careers-body{
    margin: 3em auto; 
    height: max-content; 
    width: 84%; 
}

.careers-image{
    background-image: url(./images/career.jpg); 
    background-size: cover;
    background-position: 0px 0px; 
    height: 12em;  
    width: 100%; 
}

.careers-text{
    font-size: 14px;
    height: 100%; 
    width: 100%; 
    border: 0; 
}

.careers-link{
    font-size: 18px;
    color: rgb(35, 119, 186); 
    text-decoration: none;
    cursor: pointer;
}

.careers-link:hover{
    color: rgb(25, 77, 121); 
}
