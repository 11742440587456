.investfar-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 40%; 
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 
}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.investfar-body{
    margin: 80px auto; 
    height: 50%; 
    width: 92%; 
}

.investfar-text{
    border: 0; 
    height: 80%; 
    width: 100%; 
    font-size: .9em; 
}

.investfar-link{
    font-size: 18px;
}
