.company-page{
    /* height: 44em;  */
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 18em; 
    background-image: url(../Assets/images/skyline.jpg);
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}

.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.company-body{
    margin: 3em auto; 
    height: max-content; 
    width: 92%; 
}

.company-image{
    background-image: url(../Assets/images/company.jpg);
    background-size: cover;
    background-position: 0px 0px; 
    height: 14em;  
    width: 100%; 
    border: 0; 
    border-radius: 0px; 
}

.company-text{
    font-size: 14px;
    height: 100%; 
    width: 100%; 
    border: 0; 
}

.register-button{
    margin-top: 1em; 
    height: 3em; 
    background: rgb(52,127,187); 
}

.register-button:hover{
    background: rgb(38, 95, 141); 
}