.property-table-header {
  background-color: #1c75bc;
  color: #fff;
  border: 0;
  padding-bottom: -5px;
}

.purchase-table-header{
  color: #262626;
  border: 0;
  padding-bottom: -5px;
  background-color: transparent;
}

.header-elem {
  padding: 10px;
}

.card-element {
  color: blue;
}

.proptables {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  border: 0.5px solid rgba(180, 192, 196, 0.5);
  border-collapse: collapse;
  table-layout: fixed;
}

.proptables-row {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  border: 0.5px solid rgba(180, 192, 196, 0.5);
}
.proptable-d {
  word-wrap: break-word;
  height: 100%;
}
.my-properties-body {
  margin: 2em auto;
  height: max-content;
  width: 90%;
  padding: 20px;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px;
  min-height: 39px;
  position: relative;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #495057;
}

.text-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* price: "35835.4"
propertyImg: "https://storage.googleapis.com/hale-monument-279303.appspot.com/202102022212181612303938ieb7bv07k12k4dy2lfiqxbky2.jpg"
propertyId: "94"
searchType: "2"
address: "3708 W Pioneer Pkwy, Arlington, TX 76013, USA"
title: "Test property"
latitude: "32.7139656"
longitude: "-97.1671069"
userImage: ""
userName: " "
live: "Rented"
slug_name: "test-property" */
