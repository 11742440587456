.listing-image {
  background: transparent;
  max-width: 240px;
  max-height: auto;
  min-width: 240px;
  min-height: auto;
  background-size: cover;
  background-position: center;
  border: 0;
  margin: 0;
  margin-right: 25px;
}

.listing-image img {
  width: 100%;
  height: 100%;
}

.favorite-card {
  margin-top: 1em;
  max-height: auto;
  width: 100%;
  border: 0;
  border-radius: 0;
  background: rgba(189, 191, 194, 0.09);
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #1c1c1c;
  line-height: 1.8em;
  font-weight: 400;
  cursor: pointer;
}

.favorite-card-content {
  padding: 20px;
}

.favorite-card:hover {
  transition: 0.1s;
  -webkit-box-shadow: -1px 1px 6px 0px rgb(209, 209, 209);
  box-shadow: -1px 1px 6px 0px rgb(204, 204, 204);
}

.favorite-properties-body {
  margin: 2em auto;
  height: max-content;
  width: 90%;
  padding: 20px;
}

.favorite h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  margin: 0;
  background: 0 0;
  line-height: 1.6em;
}

.favorite h3 {
  color: #0e4571;
  font-size: 20px;
}

.favorite address {
  margin: 5px 0;
  color: #5a5a5a;
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

.rating {
  margin-top: -10px;
}

.favorite-price {
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0;
  color: #1c75bc;
}

.avatar {
  vertical-align: middle;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: right;
  background-color: #0e59adc4;
}
