.price-page {
  /* height: 206em;  */
  /* height: max-content; */
  /* height: 100vh; */
  width: 100%;
}

.price-jumbo {
  background-image: url(./images/city-backdrop.jpg);
  position: relative;
  height: 40%;
  opacity: 1;
  background-size: cover;
  background-position: 0px -50px;
  background-repeat: no-repeat;
  border-radius: 0px;
  margin-bottom: 0;
  width: 100%;
}

.price-jumbo::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.price-jumbo-parallex {
  /* height: 27.7em;  */
  height: 21.3%;
  background-image: url(./images/pricing.jpg);
  opacity: 0.99;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.jumbo-content-left {
  position: relative;
  margin: 10em 2em;
  width: 80%;
  color: white;
}

.parallex-content {
  color: white;
}

.parallex-text {
  text-align: center;
  margin-top: 4px;
}

.parallex-subtext {
  margin: 0 auto;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.rectangle {
  margin: auto;
  width: 6%;
  border: 3px solid rgb(220, 157, 35);
  padding: 10px;
}

.line {
  margin: auto;
  height: 1em;
  width: 6%;
  border-bottom: 3px solid rgb(220, 157, 35);
}

.card-container {
  margin: 0 auto;
  width: 75%;
}

.price-rows {
  /* background: blue;  */
  margin: 0 auto;
}

/* parallex container for mobile  */
.parallex-container {
  height: 100%;
}

/* parallex container for desktop */
@media only screen and (min-width: 1080px) {
  .parallex-container {
    /* height: 130em; */
  }
}

.pricing-cards-container {
  margin: 0 auto;
  position: relative;
  padding: 40px 0;
}

/* price card for mobile view  */
.price-card {
  margin: 0;
  height: max-content;
  width: 100%;
  /* border: 1px solid rgb(228, 227, 227); */
  border-radius: 0;
  list-style: square;
  line-height: 2;
  font-size: 14px;
  height: 100%;
}

/* display large price card if on desktop  */
@media only screen and (min-width: 1080px) {
}

.price-card:hover {
  transition: 0.1s;
  -webkit-box-shadow: -1px 1px 11px 0px rgb(180, 180, 180);
  box-shadow: -1px 1px 11px 0px rgb(165, 165, 165);
  transform: scale(1.01);
  /* width: 320px;  */
}

.pricing-card-header {
  /* height: 15em; */
  color: white;
  background: rgb(35, 119, 186);
}

.price-card-body {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.price-card-body ul {
  flex: 1;
  max-height: 420px;
  overflow-y: auto;
}
.price-card-body ul::-webkit-scrollbar {
  display: none;
}
.price-card-body ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.price-card-body h4 {
  font-weight: 500;
}
.price-card-body li {
  margin-top: 6px;
  line-height: normal;
}

.novice-header {
  /* height: 15em; */
  color: white;
  background: rgb(254, 181, 43);
}

.circle {
  margin: 2em auto;
  /* top: 8em; 
    margin-left: 4.5em; 
    margin-right: 4.5em;  */
  background: rgb(246, 246, 246);
  border-radius: 50%;
  width: 9em;
  height: 9em;
}

.card-header-text {
  margin-top: 10px;
  text-align: center;
}

.access-button {
  margin-top: 1em;
  width: 100%;
  background: rgb(35, 119, 186);
  border-color: transparent;
}

.novice-access-button {
  margin-top: 1em;
  width: 100%;
  background: rgb(254, 181, 43);
  border-color: transparent;
}

.access-button:hover {
  background: white;
  color: rgb(35, 119, 186);
  border-color: rgb(35, 119, 186);
}

.novice-access-button:hover {
  background: white;
  color: rgb(254, 181, 43);
  border-color: rgb(254, 181, 43);
}

.getstarted {
  /* margin: 90em auto;  */
  background: rgb(244, 244, 244);
  height: 40em;
  height: 100%;
}

.getstarted-body {
  margin: 0 auto;
  width: 80%;
}

.collapse-container {
  margin: 0 auto;
}

.collapse-body {
  font-size: 14px;
  background: white;
  margin: 0 auto;
  width: 95%;
}

.collapse-paragraph {
  margin: 0 auto;
  width: 94%;
  line-height: 1.5em;
}

.collapse-button {
  background: rgb(35, 119, 186);
  border-color: rgb(35, 119, 186);
  border-radius: 0px;
  margin: 8px 1em;
  width: 100%;
}

.collapse-button:hover {
  background: rgb(35, 119, 186);
  border-color: rgb(35, 119, 186);
  color: #fff;
}

.pricing-end {
  /* margin: 5.5em auto;  */
  height: 40em;
  font-size: 14px;
}

.end-body {
  height: 100%;
  margin: 2em auto;
  width: 80%;
}

.app-store {
  background-image: url(./images/app-store.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.google-store {
  background-image: url(./images/google-play.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  margin-left: 1em;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.ios {
  background-image: url(./images/qrcodes/apple.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  position: relative;
  cursor: pointer;
}

.android {
  background-image: url(./images/qrcodes/android.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  margin-left: 1em;
  position: relative;
  cursor: pointer;
}

.qr-codes {
  max-width: 100%;
  max-height: 100px;
}

/* mobile view for app store button */
.app-store {
  height: 10%;
  width: 54%;
  display: block;
}

.google-store {
  height: 10%;
  width: 54%;
  display: block;
}

@media only screen and (min-width: 1080px) {
  .app-store {
    height: 20%;
    width: 25%;
    display: inline-block;
  }
  .google-store {
    height: 20%;
    width: 25%;
    display: inline-block;
  }
}

.app-store:hover,
.google-store:hover {
  transform: scale(1.08);
}

/* hide phone component if on mobile  */
.end-img {
  display: none;
}

/* display phone component if on large screen  */
@media only screen and (min-width: 1080px) {
  .end-img {
    display: block;
    /* position: relative;  */
    /* height: 100%; */
    width: 50%;
    max-width: 100%;
    background-image: url(./images/mob-app.png);
    opacity: 1;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    border-radius: 0px;
    margin-bottom: 0;
  }
}

.card-header .icon-container {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  border-radius: 100%;
  background: rgb(246, 246, 246);
  -webkit-box-shadow: -1px 1px 6px 0px rgb(195, 195, 195);
  box-shadow: -1px 1px 6px 0px rgb(180, 180, 180);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.icon {
  margin: 2.5em auto;
  width: 100%;
}

.qrcode {
  margin-top: 20px;
  padding-right: 2px;
  height: 35%;
  width: 35%;
}

@media (min-width: 1399px) {
  .pricing-cards-container {
    max-width: 80%;
  }
}
