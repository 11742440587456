.details-button {
    background: rgb(52, 127, 187);
    border: 0;
    width: 130%;

}

.details-button:hover {
    background-color: white;
    color: rgb(52, 127, 187);
    border: solid 1px rgb(52, 127, 187);
    margin: -10px;
}

.btn {
    border-radius: 2px;
}