.legal-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 40%; 
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}


.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.legal-body{
    margin: 5em auto; 
    height: max-content; 
    width: 92%; 
}

.card-container{
    margin-top: 2em; 
}

.legal-card-header{
    background-color: rgb(35,119,186);
    color: white; 
}


.legal-form-card{
    margin-top: 8px; 
    border: 0; 
    border-bottom: 1px solid rgb(202, 203, 207);
    cursor: pointer;
}

.legal-form-card:hover{
    transform: scale(1.02);
    transition: ease .2s;
}

.user-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
}

.doc-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 16px; 
}


.card{
    border-left: 0;
    border-right: 0; 
    border-radius: 0; 
}

.submit-button{
    background-color: rgb(35,119,186);
    height: 3em; 
    width: 16em; 
}

.submit-button:hover{
    background-color: white; 
    color:  rgb(35,119,186);
    border-style: solid; 
    border-color: rgb(35,119,186);
}