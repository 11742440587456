.add-service-page {
  height: 100%;
  margin-bottom: 40px;
}

.add-service-card {
  border: 0;
  margin: 0 auto;
  background: rgb(249, 249, 249);
  height: 100%;
  margin-bottom: 40px;
  width: 80%;
  padding: 20px;
}

.add-service-header {
  padding: 20px;
}

.small-header-row-service {
  width: 30px;
  height: 1px;
  background-color: #1c75bc;
  text-align: left;
  margin-top: 10px;
}

.service-content {
  margin: 0 auto;
  width: 90%;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}
