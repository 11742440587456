.opp-page{
    height: max-content; 
    width: 100%; 
}

.opp-jumbo{
    position: relative; 
    height: 44em; 
    background-image: url(./images/oppbanner.jpg);
    opacity: .99; 
    background-size: cover;
    background-position: 0px -170px; 
    border-radius: 0px; 
    margin-bottom: 0; 
    background-repeat: no-repeat;
}

.opp-body{
    margin: 0 auto; 
    width: 80%; 
}

.graph-card{
    border: 0; 
    position: relative;
    margin-top: 20px;
    margin-bottom: 160px;
    margin-left: auto;
    margin-right: auto;
}

.graph-img{
    display: block;
    transform: scale(10,10);
    width: 10%;
    margin: 0 auto; 
}

.jumbo-content-center{
    margin: 16em auto; 
    text-align: center; 
}

.jumbo-header-text{
    margin: 0 auto; 
    width: 50%; 
}

.jumbo-header-subtext{
    text-align: center;
}

.jumbo-header-button{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    background-color: rgb(52,127,187);
}

.jumbo-header-button:hover{
    background-color: #fff;
}

.col-content{
    margin-top: 30px; 
}

.whychoose-content{
    margin-top: 8em; 
    text-align: center; 
}

.opp-images-parent{
    display: none; 
    position: relative; 
    margin: 2em auto; 
    height: 32em; 
}


.map-img{
    position: absolute; 
    top:0px; 
    left: 100px; 
    display: block;
    width: 100%;
    height: 100%; 
    background-image: url(./images/us-map81da.png);
    background-repeat: no-repeat;
    z-index: 1;
}

.map-point-img{
    position: absolute; 
    z-index: 2;
    top:0px; 
    left: 100px; 
    height: 100%; 
    width: 100%; 
    background-image: url(./images/us-dots81da.png);
    background-repeat: no-repeat;
}

.whychoose-subtext{
    color: rgb(52,127,187);
}

.whychoose-card{
    margin: 0 auto; 
    width: 60%; 
    border: 0; 
}

.calculate-tax-content{
    margin: 8em auto; 
    width: 100%; 
    text-align: center; 
}

.calculator-inputs{
    margin: 0 auto; 
    width: 50%; 
}

.return-calculate-content{
    margin-top: 1em; 
}

.request-card{
    background: rgb(244,244,244); 
    height: max-content;
    width: 100%;
}

.request-content{
    margin: 0 auto; 
    width:70%; 
}

.request-text{
    text-align: center; 
    margin-top: 2em; 
}

.request-form{
    margin: 2em auto; 
    width: 80%; 
}

.form-row{
    margin-top: 1em; 
}

.radio-row{
    margin: 0 auto; 
    width: 40%; 
}

.radio{
    margin: 0 auto; 
}

.button-row{
    margin: 0 auto; 
    width: 20%; 
}
.submit-button-op{
    margin-top: 1em; 
    background: rgb(52,127,187);
    border-color: rgb(52,127,187);
    width: 100%; 
}

.button:hover{
    background: white; 
    color: rgb(52,127,187);
}

.additional{
    margin: 2em auto; 
    width: 80%; 
}

.additional-text{
    margin-top: 2em; 
    text-align: center;
}

.book-card{
    margin: 1em auto; 
    background: transparent; 
    height: 16em; 
    width: 60%; 
}

.book-img{
    height: 70%; 
    background-image: url(./images/ivest_doc.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.book-text{
    margin-top: 0.5em; 
    text-align: center;
    color: rgb(52,127,187);
}

.book-card:hover{
    cursor: pointer; 
    border: solid 2px; 
    border-color: rgb(52,127,187);
}

.return-graph{
    margin: 2em auto; 
    width: 70%; 
}

/* display images component if on large screen  */
@media only screen and (min-width: 1080px){
    .whychoose-card{
        margin: 0 auto; 
        width: 20%; 
        border: 0; 
    }

    .opp-images-parent{
        display: block; 
    }

    .book-img{
        height: 100%; 
        width: 100%; 
    }

    .book-card{
        width: 20%; 
    }
}

