.nomatch-body{
    height:30em; 
}

.jumbo{
    position: relative; 
    height: 100%; 
    background-image: url(./images/city-backdrop.jpg);
    opacity: .8; 
    background-size: cover;
    background-position: 0px -520px; 
    border-radius: 0px; 
    margin-bottom: 0; 
}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin-top: 170px;
    margin-left: 200px; 
    width: 32em; 
    color: white; 
}