.company-page{
    height: 100em; 
    width: 100%;
}

.jumbo{
    position: relative; 
    height: 18em; 
    background-image: url(../Assets/images/skyline.jpg);
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}


.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.checkboxes{
    margin-left: 1em; 

}

.company-account{
    margin: 4em auto; 
    width: 80%; 
    text-align: center;
}

.company-register-card{
    height:max-content; 
    margin: 0 auto; 
    /* width:64%;      */
    width: 100%; 
    background-color: rgb(249,249,249);
    padding-bottom: 200px;
}

@media only screen and (min-width: 1080px){
    .company-register-card{
        height: 70em;
        padding-bottom: 200px;
    }
}

.register-form{
   margin: 0 auto; 
   width: 80%;
}

.email, .password, .remember, .forgot{
    text-align: left;
}

.email-control, .password-control{
    width: 36em; 
}

.sign-button{
    height:3em; 
    width: 22em; 
    background-color: rgb(52,127,187);
    color: white; 
}

.oauth-login:hover{
    fill: black; 
}

.signup-content{
    margin-left: 20px; 
}

.make-name{
    text-align: left;
}

.name-control{
    width: 34em; 
}

.make-email, .make-web, .make-password, .attach{
    text-align: left; 
}
.make-email-control, .make-web-control{
    width:15em; 
}

.make-password-control{
    width:36em; 
}

.sign-up-button{
    margin-top: 30px; 
    height:3em; 
    width: 80%; 
    border-color:rgb(52,127,187);
    background-color: rgb(211,211,211);
    color: white; 
}

.sign-up-button:hover{
    color: rgb(52,127,187);
    background-color: rgb(211,211,211);
}