.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
    display: block;
    background: #000000;
    margin: 0px auto;
    border: 1px solid #c7c7c7;
    box-shadow: 0px 0px 0px 5px #00000014;
  }
  
  .profile-pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .changeProfileBtn label{
    margin: 10px auto;
    color: #000;
    font-weight: bold;
    cursor: pointer;
  }
.changeProfilePicBtn {
    text-align: center;
    margin: 5px 0 15px 0px;

}



.profile-form-outer {
    position: relative;
    padding: 50px 35px;
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
}


.default-title h2 {
    font-size: 15px;
    font-weight: 500;
    color: #1c1c1c;
    text-transform: uppercase;
}

.default-title h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    border-bottom: 1px solid #1c75bc;
}

.width-full, .profile-form-control {
    width: 100%;
}

.inner-banner {
    background-image: url(./images/city-backdrop.jpg); 
}


.inner-banner{
  position:relative;
  padding: 200px 0px 40px;
  color:#ffffff;
  background-color:#ffffff;
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
}
.inner-banner:before{
  content:'';
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background:rgba(4,4,4,0.50);
}
.inner-banner .auto-container{
  position:relative;
  z-index:1;
}
.inner-banner h1{
  position:relative;
  font-size:32px;
  line-height:44px;
  text-transform:uppercase;
  font-weight:600;
  letter-spacing:1px;
}

.plan-container{
  background: transparent; 
  border: 0;
  align-items: center;
}

.plan{
  width: 20em; 
  background: rgb(52, 127, 187);
  color: white; 
  font-size: 12px;
}

.plan-body{
  align-items: center;
  text-align: center;
}

.cancel-button{
  background: white;
  color: rgb(52, 127, 187);
  border: 0; 
  width: 100%; 
}

.cancel-button:hover{
  background: rgb(255, 0, 0);
  color: white; 
}