.pay-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    height: 40%; 
    opacity: 1; 
    background-size: cover;
    background-position: 0px -50px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}

.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.64);
    margin-bottom: 0; 
}

.jumbo-content-left{
    position: relative; 
    margin: 10em 2em; 
    width: 80%; 
    color: white; 
}


.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.pay-body{
    margin: 5em auto; 
    height: max-content; 
    width: 92%; 
    border: 0
}

.pay-card-container{
    margin-top: 2em; 
}

.pay-card-header{
    background-color: rgb(35,119,186);
    color: white; 
}


.pay-form-card{
    margin-top: 8px; 
    border: 0; 
    border-bottom: 1px solid rgb(202, 203, 207);
    cursor: pointer;
}

.legal-form-card:hover{
    transform: scale(1.02);
    transition: ease .2s;
}

.user-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
}

.doc-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 16px; 
}


.pay-card{
    border-left: 0;
    border-right: 0; 
    border-radius: 0; 
    height: 40em; 
    width:100%; 
}

.submit-button{
    background-color: rgb(35,119,186);
    height: 3em; 
    width: 16em; 
}

.submit-button:hover{
    background-color: white; 
    color:  rgb(35,119,186);
    border-style: solid; 
    border-color: rgb(35,119,186);
}

.info-card{
    height: 25em; 
    border-radius: 0;
    border: 0; 
    background-color: rgb(246,246,246);
}

.info-card-header{
    background-color: rgb(35,119,186);
    border-radius: 0;
    border: 0; 
    color: white; 
}

.plan-card{
    height: 40em; 
    width: 90%; 
    background-color: rgb(246,246,246);
    border: 0; 
}

.info-form{
    margin: 20px auto; 
    width: 98%;
}

.pay-form-row{
    margin-top: 10px;
}

.privacy-link{
    color: rgb(35,119,186);
}

.pay-checkbox{
    margin-top: 20px;
    margin-left: 10px;
}

.info-span{
    color: gray; 
    font-size: 12px;
    margin: 0 auto; 
    width: 90%; 
}

.plan-card-body{
    margin: 40px auto; 
    width: 95%; 
    text-align: center;
}

.property-table-header{
    font-size: 12px;
    color: black; 
    border: 0;
    padding-bottom: -5px;
    background-color: transparent;
  }
  
  .header-elem {
    padding: 10px;
  }

  .header-elem-2{
      font-size: 8px;
      color: gray; 
  }

  .orders-card{
      margin: 60px auto; 
      width: 95%; 
      background: #fff;
      height: 30em; 
      overflow-y: scroll;
      border: 0; 
  }

  .orders-card-row{
      margin-top: 20px; 
      background: rgb(242, 244, 246); 
      min-height: 4em; 
      border-radius: 14px;
      color: rgb(111, 108, 108);
  }

  .orders-card-row:hover{
      transform: scale(1.009); 
      transition: .1s ease;
  }

  .orders-card-header{
      height: 4em; 
      background: rgb(35,119,186);
      color: white; 
  }