    .invest-boxable h4{
        position: relative;
        padding-bottom: 11px;
        margin-bottom: 15px;
        font-size: 25px;
        font-weight: 700;
        color: #043e62;
    }
    .invest-boxable h1 strong{
        color:#204170
    }
    .invest-boxable h4:after{
        background: #f7931e;
        content: "";
        width: 70px;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0px;
    }
    .invest-boxable table td,
    .invest-boxable table th {
        font-size: 18px;
        padding: 5px;
        width: 200px;
        color: #4a4a4a;
    }
    .invest-boxable table th{
        color: #043e62;
        font-weight: 400;
    }
    .image-invest {
        margin-bottom: 30px;
    }
   
    .invest-boxable{
        margin-bottom: 20px;
    }
    .invest-boxable ul {
        padding-left: 20px;
    }
    .invest-boxable ul li,
    .invest-boxable p{
        color: #4a4a4a;
        font-size: 17px;       
    }
    .invest-boxable ul li {
        list-style: initial;
        margin-bottom: 28px;
    }
    .investleft h1 {
        font-size: 29px;
        line-height: normal;
        margin-bottom: 38px;
        font-weight: bold;
        color: #043e62;
    }
    .investleft h1 strong,
    .invest-boxable p strong,
    .invest-boxable li strong  {
        color: #043e62;
    }
    .invest-boxable ol li::marker {
        color: #ff5e00;
        font-weight: bold;
    }
    .modal-main{
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0px;
        background: rgba(0,0,0,.35);
        z-index: 9999999999999;
        padding: 15px;
        overflow-y: scroll;
    }
    .modal-main .modal-dialog {
        max-width:679px;
        margin: 1.75rem auto;
        background: #ffff;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        pointer-events: inherit;
    }
    .modal-main button.close {
        position: absolute;
        right: 0;
        background: #ffff;
        width: 47px;
        opacity: 1;
        font-size: 35px;
        height: 42px;
        border-radius: 0px 0 0px 14px;
        font-weight: normal;
    }
    .modal-main button.close:focus{
        outline: none;
    }
    .closemodel{
        color: red;
    }
    .closemodel:hover{
        color: #ff2020;
    }

    .form-group input {
        width: 100%;
        height: 44px;
        padding: 10px;
        border: solid 1px #000;
    }
    .checkboxform {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    iframe{
        z-index: 99 !important;
    }
    .checkboxform input {
        width: 27px;
        padding: 14px;
        height: 80px;
        margin: 10px;
        accent-color: #043e62;
    }
    .checkboxform label {
        width: 49%;
        display: flex;
        align-items: center;
        border: solid 1px #ccc;
        color: #000;
        height: 48px;
        cursor: pointer;
    }
    
    .learmorebtn {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .learmorebtn button {
        background: #043e62;
        padding: 10px 20px;
        color: #fff;
        border: none;
    }
    .learmorebtn ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom:0px;
    }
    .learmorebtn ul li {
        list-style-type:none;
        list-style: none;
    }
    .learmorebtn ul li a {
        color: #0f75e1;
        font-size: 14px;
        margin-left: 10px;
        font-weight: 300;
        text-decoration: underline;
    }
    .accreditedlink.text-center a {
        color: #043e62;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 17px;
        position: relative;
    }
    .accreditedlink.text-center a:after {
        background: #f7931e;
        height: 1px;
        width: 90%;
        position: absolute;
        content: "";
        left: 0px;
        bottom: 5px;
    }
    .accreditedlink.text-center a svg {
        margin-left: 10px;
        color: #f7931e;
    }
    .click-proceed a {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 12px 45px;
        display: block;
    }
    .form-group label {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
    }
    .video-invest-pop {
        max-width: 380px;
        height: 172px;
        width: 100%;
    }
    .video-invest-pop iframe {
        width: 100%;
        height: 100%;
    }
    .Disclaimer label {
        font-size: 17px;
        font-weight: 600;
        margin-top: 38px;
    }
    .Disclaimer p {
        font-size: 10px;
    }

    body.modal-open{
        overflow: hidden;
    }
    .invest-side-bar-form form{
        position: sticky;
        top: 110px;
        background:#f7f7f7;
        padding: 30px;
    }
    .right-side h6 {
        text-align: center;
        font-size: 19px;
        margin-bottom: 20px;
        font-weight: 600;
        color: #043e62;
    }
    .right-side .form-group input {
        border: none;
        border-bottom: solid 1px #afafaf;
        height:30px;
        padding: 0;
        background: transparent;
    }
    .investleft h3 {
        font-size: 25px;
        color: #043e62;
    }
    .right-side .form-group input:focus {
        border: 0;
        outline: none;
        border-bottom: solid 2px #424242 !important;
    }
    .right-side label {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .right-side button { 
        width: 100%;
        background: #043e62;
        color: #fff;
        padding: 8px 3px;
        border-radius: 0;
        font-size: 14px;
        height: 40px;
    }
    .right-side button:hover{
        color:#fff;
    }
    .right-side ul {
        display: flex;
        justify-content: center;
        margin-top: 46px;
    }
    .lastsection ul li {
        margin-bottom:8px;
    }
    .buttom-section{
        margin-top: 60px;
    }
    .PositionsLimited {
        font-weight: bold;
        margin: 20px 0px;
        color: #212529;
    }

    .invest-how-it-work{
        margin: 40px 0 100px 0;
        justify-content: center;
        display: flex;
    }
    
    .invest-how-it-work .howItWork-slider-item img {
        max-width: 290px;
    }

    .invest-how-it-work .howItWork-slider {
        background-size: 320px;
        width: 300px;
    }
    .invest-how-it-work .col-md-6.wow{
        display: flex;
        justify-content: center;
    }
    .learmore-modal {
        justify-content: center;
        text-align: center;
    }
    .learmore-modal.modal-main .modal-dialog {
        background: transparent;
    }
    .learmore-modal .modal-dialog .modal-body {
        background: #fff;
        border-radius: 8px;
    }

    @media(max-width:360px){
        .accreditedlink.text-center a {
            font-size: 14px;
        }
        .checkboxform input {
            width: 21px;
            height: 22px;
            margin: 7px;
        }
        .learmorebtn ul li a{
            margin-left:0px;
        }
        .learmorebtn ul {
            flex-direction: column;
            margin-top:25px;
            align-items: flex-start;

        }

    }
