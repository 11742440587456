.property-listing-page {
    height: max-content;
    margin-bottom: 500px;
}

.listing-content {
    margin-top: 50px;
}

.jumbo-content-left {
    position: relative;
    margin: 8em 5em;
    width: 80%;
    color: white;
}

/* @media (max-width: 900px) {
    .carousel {
      width:auto;
      height:auto;
    }
} */

.thumbnails {
    width: 100%;
    margin-left: -15px;
    margin-top: 40px;
    cursor: pointer;
}

.image-container {
    margin-right: 5px
}

.sidebar-light {
    width: 100%;
    position: relative;
    display: block;
    background: #f4f5f6;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 15px;
    border-left: 2px solid transparent;
    border-radius: 0 2px 2px 0;
    transition: .3s ease-in;
    -webkit-transition: .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    border-color: #1c75bc;
    color: #1c75bc;
    margin-top: 8px;
}

.sidebar-dark.shieldpay {
    text-align: center;
    padding-bottom: 5px;
}

.sidebar-dark.shieldpay:hover {
    text-align: center;
    color: #1c75bc;
    background: #ffffff;
    border: solid 1px #1c75bc;
    cursor: pointer;
    padding-bottom: 5px;
}

.sidebar-dark {
    width: 100%;
    margin-top: 8px;
    position: relative;
    display: block;
    color: #fff;
    text-transform: uppercase;
    background: #1c75bc;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 20px;
    transition: .3s ease-in;
    -webkit-transition: .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    margin-top: 15px;
    border: 1px solid #1c75bc;
}

.small-header-row {
    width: 30px;
    height: 1px;
    background-color: #1c75bc;
    text-align: left;
}

.large-header-row {
    width: 100%;
    height: 1px;
    background-color: #616d772f;
}

.small-header-row.profile {
    width: 30px;
    height: 1px;
    background-color: #1c75bc;
    text-align: left;
}

.make-offer-card {
    border: 0;
    margin: 0 auto;
    height: 20em;
    width: 40em;
}

.offer-form {
    margin-top: 1em;
}

.offer-control {
    margin: 0 auto;
    height: 1em;
    width: 100%;
}

.offer-message{
    resize: none;
}

.send-button {
    background: rgb(52, 127, 187);
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 20px;
}

.send-button:hover {
    background-color: white;
    color: rgb(52, 127, 187);
    border: solid 1px rgb(52, 127, 187);
}

.allby-button {
    background: rgb(52, 127, 187);
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 20px;
    margin-top: 10px;
}

.allby-button:hover {
    background-color: white;
    color: rgb(52, 127, 187);
    border: solid 1px rgb(52, 127, 187);
}

.tools-button {
    background: rgb(52, 127, 187);
    width: 100%;
    border: 0;
    border-radius: 200px;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 20px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
}

.tools-button:hover {
    background-color: white;
    color: rgb(52, 127, 187);
    border: solid 1px rgb(52, 127, 187);
}

.property-description-card {
    border: 0;
    background: rgb(249, 249, 249);
    height: 100%;
    width: 100%;
    margin-top: 20px

}

.property-tools-card {
    border: 0;
    background: rgb(249, 249, 249);
    height: 100%;
    width: 100%;
    margin-top: 20px;
    padding: 20px
}

.property-details-card {
    border: 0;
    background: rgb(249, 249, 249);
    height: 100%;
    width: 100%;
    margin-top: 20px
}

.property-description-card .card-header {
    border: 0;
    color: rgb(249, 249, 249);
    background-color: rgb(52, 127, 187);
    height: 100%
}

.property-details-card .card-header {
    border: 0;
    color: rgb(249, 249, 249);
    background-color: rgb(52, 127, 187);
    height: 3em
}

.property-description-card .body {
    color: rgb(52, 127, 187);
    padding: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
}

.property-details-card .body {
    padding: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
}

.property-description-card .card-body {
    padding: 0px
}

.property-details-card .card-body {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 30px;
}

.description-title {
    color: rgb(82, 93, 104);
}

.property-description-card .decription-keywords {
    color: rgb(72, 82, 90);
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    background: rgba(222, 223, 224, 0.425);
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 5px;
}

.property-details-card .details-keywords {
    color: rgb(72, 82, 90);
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    background: rgba(222, 223, 224, 0.425);
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.amenity-column{
    text-align: center;
}

.icon-block {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin: 20px 5px 5px;
    border: 1px solid #e1e1e1;
    display: inline-block;
    padding-top: 20px;
    padding-left: 14px; 
}

.amenity-icon{
    display: block;
    margin-bottom: 10%;
    margin-left: 15%;
    margin-right: 15%; 
    width: 50%; 
}

.favor {
    cursor: pointer;
}

.maximize {
    position: absolute;
    z-index: 99;
    top: -10px;
    right: 50px;
    /* padding: 7px 15px; */
    background-color: rgb(255, 255, 255);
    text-align: right;
    display: inline-block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
  }

  .icon {
    margin-top: 0px;
    width: 30px;
    height: 30px;
  }

  .component-collapse-body{
    font-size: 14px; 
    background: transparent; 
    margin: 0 auto; 
    width: 95%; 
}

.listing-component-card{
    margin-top: 20px;
    height: 15em; 
    overflow-y: scroll;
}

.listing-component-compare-card{
    margin-top: 20px;
    height: 25em; 
    overflow-y: scroll;
}

.component-item-container{
    margin: 10px auto; 
    width: 95%; 
    border: solid 1px rgba(0,0,0,0.1); 
    margin-bottom: 5px;
    padding: 10px; 
    border-radius: 15px;
    background: rgba(52, 127, 187, 0.05);
}

.component-compare-container{
    margin: 10px auto; 
    width: 95%; 
    background: red;
    text-align: center;
}

.icon-photo{
    height: 4em; 
    width: 4em; 
}

.component-badge{
    padding: 2px;
}

.component-spinner{
    margin-left: auto; 
    margin-right: auto; 
    margin-top: auto; 
    margin-bottom: auto;
}

.compare-modal{
    background-color:rgb(52, 127, 187);
    color: white; 
}

.modal-heading{
    margin-left: auto;
    margin-right: auto;
}

.compare-image{
    background: blue; 
    height: 8em; 
    width: 8em; 
}

.proptables {
    width: 100%;
    padding: 25px;
    margin: 0 auto;
    border: 0.5px solid rgba(180, 192, 196, 0.5);
    border-collapse: collapse;
    table-layout: fixed;
  }

  .table-card{
      margin: 2em auto; 
      width: 80%; 
      border: 0; 
  }

  .compare-table-row{
      background-color: rgb(52, 127, 187);
  }

  .column-item{
      border: 1px solid black; 
  }

  .row-item{
      padding:10px;

  }

  .proptable-d-compare{
      height: 12em;
      background: rgba(244,247,250,1); 
      border: solid 1px rgba(72, 82, 90,.2);
  }

  .madden-card{
      margin: 0 auto; 
      height: 10em; 
      width: 90%; 
  }

  .madden-card-placeholder{
      border: 0; 
      background: transparent; 
      margin: 0 auto; 
      height: 10em; 
      width: 90%; 
  }

  .compare-check{
      margin: 0 auto; 
      width: 70%;
      font-weight: normal;
  }

  .compare-photo{
    border: 0; 
    height: 100%; 
    width: 100%; 
  }