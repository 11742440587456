.improvement-page{
    height: max-content; 
    width: 100%; 
}

.jumbo{
    position: relative; 
    min-height: 310px!important; 
    opacity: 1; 
    background-size: cover;
    background-position: 0px -80px; 
    background-repeat: no-repeat;
    border-radius: 0px; 
    margin-bottom: 0; 
    width: 100%; 

}
.improvement-body.container h6 {
    font-size: 18px;
    letter-spacing: 1px;
}
.jumbo::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.51);
    margin-bottom: 0; 
}
.improvement-description span {
    font-size: 14px;
}

.jumbo-content {
    position: relative;
    padding: 0em -6.7em;
    width: 80.5%;
    color: white;
    text-align: end;
    text-align: end;
    padding-top: 220px;
}
.jumbo-content h2 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
}


.button{
    margin-left: 8px;
    background-color: rgb(52,127,187);
}

.improvement-body{
    margin: 5em auto; 
    height: max-content; 
    width: 92%; 
}
.card{
    border-radius: 0!important; 
}
/* .card-container{
    margin-top: 2em; 
}

.card{
    border-radius: 0; 
}

.card:hover{
    transform: scale(1.02); 
    transition: ease .2s; 
}

.card-content{
    text-align: center;
} */
/* -------------------ssss-------------- */
/* .content-wrap {
    background: rgb(250, 248, 248);
}
.card{
    background-color: rgb(250, 248, 248);
} */
.card-content {
    text-align: center;

    padding: 0px 38px;
    min-height: 100px;
}
.card-content p1{
font-size: 13px!important;
}
li {
    list-style-type: circle;
}
.getstarted-body h3 {   
    position: relative;
}
.getstarted-body h3:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 2px;
    background: #7cbef3;
    left: 0;
    bottom: -2px;
}
.improve-carti-body.card-body {
    text-align: left;
}
.improve-carti-body.one.card-body {
    margin: 0px 20px 0px 160px!important;
    max-width: 518px!important;
}
.improve-carti-body h4 {
    position: relative;
    font-size: 27px;
}
.improve-carti-body h4:after {
    position: absolute;
    content: "";
    width: 30px;
    height: 1px;
    background: #7fbaeb;;
    left: 0;
    bottom: -2px;
}
.footer-col ul li {
    list-style-type: none;
}
/* -------------- ssss-----------------*/
@media only screen and (min-width: 1080px){

}

.improve-carti-body {
    margin: 0px 36px;
    max-width: 495px;
}
.improve-carti-body.card-body a {
    color: rgb(35,119,186);
}

.icon-container{
    width: 80px;
    height: 80px;
    background: rgb(52,127,187);
    border-radius: 50%; 
    margin: 40px auto 25px; 
    position: relative;
    display: flex; 
    justify-content: center;
    box-shadow: 0px 1px 7px -1px;
    align-items: center;
}
.btnmargin.row {
    margin-left: 0px;
}
button.collapse-button.btn.btn-primary .icon{
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin: 0;
    transform: translate(0%, -50%);
    width: 100%;
    position: absolute;
    width: 18px!important;
    height: 18px;
    padding: 4px;
    font-size: 48px!important;
   
}

.improvement-description{
    margin: 0 auto; 
    width: 80%; 
    text-align: center;
    margin-bottom: 60px;
}
.improve-carti-body.card-body p1,
.improve-carti-body.card-body li {
   
    font-size: 14px;
}
/* .improve-content .col {
    padding: 30px 7px;
} */
.card-row {
    padding: 35px 7px;
}
.improvement-button{
    background: rgb(35,119,186); 
    color: white; 
}

.legal-card-header{
    background-color: rgb(35,119,186);
    color: white; 
}



.user-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
}

.doc-icon{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 16px; 
}


.submit-button{
    background-color: rgb(35,119,186);
    height: 3em; 
    width: 16em; 
}

.submit-button:hover{
    background-color: white; 
    color:  rgb(35,119,186);
    border-style: solid; 
    border-color: rgb(35,119,186);
}

.improve{
    margin: 0 auto; 
    width: 100%; 
    background: rgb(249,249,249);
    height: 15%; 
}

.improve-content{
    margin: 0 auto; 
    width: 100%; 
}

.improve-carti{
    height: 72%; 
    width: 100%; 
    margin: 1em 1em; 
    background: transparent; 
    border: 0; 
}

.improve-img{
    display: inline-block; 
    /* height: 70vh; */
    /* -----sssss----- */
    /* height: 80vh; */
    /* -----sssss----- */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: linear-gradient(rgba(104, 104, 104, 0.5), rgba(31, 104, 199, 0.5)), */
    background-image: 
    /* url(../Assets/images/pricing_img-2.jpg);  */
    url(../Assets/images/homeimprovementnetwork-1.jpg); 
    color: white; 
    text-align: right; 
}


.improve-img-second{
    display: inline-block; 
    /* height: 70vh; */
    /* -----sssss----- */
    /* height: 80vh; */
    /* -----sssss----- */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: linear-gradient(rgba(104, 104, 104, 0.5), rgba(31, 104, 199, 0.5)), */
    background-image: 
    /* url(../Assets/images/pricing_img-2.jpg);  */
    url(../Assets/images/homeimprovementnetwork-2.jpg); 
    color: white; 
    text-align: right; 
}

.improve-body{
    margin: 10em 2em; 
}

.getstarted{
    background: rgb(255, 255, 255);
    height: 100%; 
}

.getstarted-body{
    margin: 0 auto; 
    width: 80%; 
}

.collapse-container{
    margin: 0 auto; 
}

.collapse-body{
    font-size: 14px; 
    background: white; 
    margin: 0 auto; 
    width: 95%; 
}

.collapse-paragraph{
    margin: 0 auto; 
    width: 94%; 
    line-height: 1.5em;
}

.collapse-button{
    background: rgb(35,119,186);
    border-color:  rgb(35,119,186);
    border-radius: 0px;
    margin: 8px 1em; 
    width: 100%; 
    font-size: 12px; 
    text-align: left;
}

.collapse-button:hover{
    background: rgb(35,119,186);
    border-color:  rgb(35,119,186); 
    color: white; 
    transform: scale(1.02); 
    transition: ease .2s; 
}
button.collapse-button.btn.btn-primary {
    position: relative;
    padding: 4px 25px!important;
}
button.collapse-button.btn.btn-primary:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #f7f5f5;
    left: 12px;
    top: 40%;
    border-radius: 50%;
}

.faqcard{
    background: rgb(35,119,186);
    /* ---------------ssss------------- */
    height: 86%;
    top: 2%;
    border-radius: 0px;
    /* ---------------ssss------------- */
} 
/* .faqicon-container{
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 50%; 
    margin: 4vh auto; 
    position: relative;
    display: flex; 
    justify-content: center;
} */
.faqicon-container {
    width: 70px;
    height: 70px;
    background: white;
    border-radius: 50%;
    /* margin: 4vh auto; */
    /* padding: 23px; */
    margin: 65px auto 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faqcard-content{
    margin: 0 auto; 
    /* width: 50%;  */
    /* -----ssss----- */
    width: 65%; 
    /* -----ssss----- */
    text-align: center;
}

.improve-footer{
    text-align: left;
    color: gray; 
    font-size: 12px;
}
.getstarted.container h3{
    position: relative;
}
.getstarted.container h3:before {
    position: absolute;
    width: 35px;
    height: 2px;
    background: #7fbaeb;
    content: "";
    bottom: 0px;
}
.improve-footer ol li{
    list-style-type: decimal;
    padding: 3px 0px;
}
.improve-footer ol{
padding: 10px 15px;
}
.improvement-button{
    text-transform: uppercase;
}
