.main-footer {
  color: white;
  background-color: rgb(18, 70, 112);
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
  /* margin-top: 300px; */
}

.footer-container {
  margin: 0 auto;
  width: 86%;
}

.investfar-logo {
  height: 3em;
  margin-bottom: 3em;
}

.footer-col {
  font-size: 14px;
}

.row-end {
  text-align: center;
  font-size: 14px;
  background: #012d50;
  padding: 20px 20px 5px;
}

.form-control {
  height: 3em;
}

.list-unstyled {
  margin-top: 20px;
}

.subscribe-button {
  width: 3em;
  color: white;
  background-color: rgb(52, 127, 187);
}

.subscribe-hr {
  background-color: rgb(65, 107, 141);
}

.row-end {
  text-align: center;
  font-size: 14px;
}

a {
  color: white;
}

a:hover {
  color: rgb(95, 156, 206);
}
img.pinup {
  margin-bottom: 18px;
}
.social-item:hover {
  color: rgb(95, 156, 206);
  cursor: pointer;
}
.social-item:hover svg{
  color: white;
}
.footer-col h6 {
  padding: 0rem 1.2rem;
  text-transform: uppercase;
  font-size: 13px;
}
.footer-col.col h6{
  padding: 0px!important;
}
.footer-col p {
  font-weight: 500;
  max-width: 330px;
}
ul.list-unstyled .col {
  padding: 0px 0px 0px 6px!important;
}

.list-unstyled a {
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  font-size: 12px;
}
li.social-item {
  display: flex;
  align-items: center;
 
}
li.social-item svg {
  margin-right: 5px;
  
  font-size: 17px;
}
.main-footer .row {
  padding-bottom: 50px;
}
.footericon{
  display: flex;
  align-items: center;
}
.footericon img {
  width: 24px;
  margin-right: 10px;
}
li.social-item {
  padding-bottom: 10px;
}
.input-group-append .btn{
  z-index: 0!important;

}
.textc{
  text-transform:capitalize!important;
  font-weight: 400!important;
}

.list-unstyled .nav-link:hover{
  color: rgb(95, 156, 206);
}
li.social-item img {
  width: 24px;
  padding: 4px;
}