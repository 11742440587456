.property-search{
    margin: 2em auto; 
    width: 90%; 
    height: 11%; 
}


.noresults{
    color: red;
}

.list-card-property{
    margin-top: 1em; 
    height: 400px; 
    border: 0; 
    border-radius: 0;
    background: rgb(246,246,246); 
}

.date-range{
    color:#1c75bc;
}

.inner-lisitng{
    padding: 30px;
    text-transform: capitalize;
}

.list-card:hover{
    transition:0.1s; 
    -webkit-box-shadow: -1px 1px 6px 0px rgb(209, 209, 209); 
    box-shadow: -1px 1px 6px 0px rgb(204, 204, 204);
}

.search-results{
    margin-bottom: 20px;
    height: 800px;
}

 .header {
    color: #1c75bc;
    margin-bottom: 5px;
    font-size: 15px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
}

.item-location {
    color: #5a5a5a;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 45px;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #1c75bc;
}

.price {
    position: absolute;
    z-index: 99;
    top: 25px;
    left: 0;
    background: rgb(28,117,188,.8);
    color: #fff;
    padding: 7px 15px;
    text-align: right;
    font-size: 16px;
    min-width: 120px;
}



.listing-img{
    background: transparent;
    max-width: 100%;
    min-height: 300px;
    background-size: cover;
    background-position: center;
    border:0;
    margin:0;
}

.listing-inner{
    padding:20px;
    margin:0px
}

 .details {
   padding:20px 
}
a{
    text-decoration: none!;
    cursor: pointer!;
}

a {
    font-style: none!;
  }