.App {
  text-align: center;
}

.page-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  width: 100%;
}

.content-wrap {
  flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
}

p {
  position: relative;
  line-height: 1.7em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 